import Button from "react-components/dist/components/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  error: any;
  resetErrorBoundary: any;
};

function ErrorFallback({ error, resetErrorBoundary }: Props) {
  const navigate = useNavigate();
  if (error.message?.includes("Loading chunk")) {
    return (
      <div
        role="alert"
        className="border border-yellow-500 bg-yellow-100 px-8 py-4 text-center flex flex-col gap-y-4"
      >
        <h6 className="text-sm">Ada versi terbaru!</h6>
        <span className="">
          Silakan muat ulang halaman dengan kombinasi tombol CTRL + SHIFT + R
          atau klik tombol di bawah. Jika masih mengalami kendala yang sama
          silakan hubungi Customer Support kami.
        </span>
        <span className="">
          <Button state="secondary" size="sm" className="px-4">
            <button
              type="button"
              onClick={() => {
                navigate(0);
              }}
            >
              Muat ulang halaman
            </button>
          </Button>
        </span>
      </div>
    );
  }

  if (error?.response?.status === 404) {
    return (
      <div
        role="alert"
        className="border border-yellow-500 bg-yellow-100 px-8 py-4 text-center"
      >
        {error?.isAxiosError ? error?.response?.data?.message : error?.message}
        <Button state="secondary" size="sm" className="px-4">
          <button onClick={resetErrorBoundary}>Reset</button>
        </Button>
      </div>
    );
  }

  return (
    <div role="alert" className="border border-red-500 bg-red-100 px-8 py-4">
      There was an error:{" "}
      <pre style={{ whiteSpace: "normal" }}>
        {error?.isAxiosError ? error?.response?.data?.message : error?.message}
      </pre>
      <Button state="secondary" size="sm" className="px-4">
        <button onClick={resetErrorBoundary}>Reset</button>
      </Button>
    </div>
  );
}

export default ErrorFallback;
